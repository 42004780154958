/* unplugin-vue-components disabled */import __unplugin_components_1 from '/builds/rose-metrics/rose-metrics/vui/src/components/AutoCompleteBehandler.vue';
import __unplugin_components_0 from '/builds/rose-metrics/rose-metrics/vui/src/components/AutoCompleteBehandler.vue';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"behandler-table"},[_c(VCard,{staticClass:"pt-2 pb-3 px-2",attrs:{"color":"grey lighten-4","flat":"","outlined":""}},[_c(VTextField,{staticClass:"mb-2",attrs:{"type":"search","placeholder":"Filter","solo":"","hide-details":"","dense":"","flat":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c(VIcon,{attrs:{"small":"","color":"primary"}},[_vm._v("fa-regular fa-filter")])]},proxy:true}]),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('div',{staticClass:"gridWrap"},[_c('ag-grid-vue',{staticClass:"lgrid ag-theme-balham",attrs:{"column-defs":_vm.columnDefs,"row-data":_vm.behandler,"getRowId":_vm.getRowId,"locale-text":_vm.localeText,"grid-options":_vm.gridOptions},on:{"grid-ready":_vm.onGridReady,"cellClicked":_vm.onCellClicked,"selection-changed":_vm.onSelectionChanged}})],1),_c(VContainer,{staticClass:"mt-2",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('h4',[_vm._v("Behandler hinzufügen")])]),_c(VCol,{staticClass:"col-md-4 col-sm-12"},[_c(__unplugin_components_0,{attrs:{"behandler-list":_vm.behandlerNichtZugeordnetAktiv,"label":"Aktive Behandler"},on:{"save":_vm.addBehandlerToZuordnung}})],1),_c(VCol,{staticClass:"col-md-4 col-sm-12"},[_c(__unplugin_components_1,{attrs:{"behandler-list":_vm.behandlerNichtZugeordnetVeraltet,"label":"Inaktive Behandler"},on:{"save":_vm.addBehandlerToZuordnung}})],1),_c(VCol,{staticClass:"ml-auto pr-0",attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"color":"red","text":"","disabled":!_vm.showDeleteAllSelected,"small":""},on:{"click":_vm.deleteItems}},[_c('font-awesome-icon',{staticClass:"mx-1",attrs:{"size":"sm","icon":["fas", "trash"]}}),_c('div',{staticClass:"pl-2"},[_vm._v("Auswahl Löschen")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }