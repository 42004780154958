import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.some.js";
import { defineComponent } from 'vue';
import { german } from '@rose/common-ui';
import { settingsBehandlerTeamsBehandlerColumnsDefs } from '@/components/columns/settings/behandlerTeamsBehandler';
import AutoCompleteBehandler from '@/components/AutoCompleteBehandler.vue';
import { chain } from 'lodash';
import { getTerminBehandler } from '@/helpers/settings';
import { behandlerStore } from '@/state/behandlerStore';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  components: {
    AutoCompleteBehandler: AutoCompleteBehandler
  },
  data: function data() {
    return {
      localeText: german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: settingsBehandlerTeamsBehandlerColumnsDefs(),
      showDeleteAllSelected: false,
      nichtZugeordnetAktivAdded: [],
      nichtZugeordnetVeraltetAdded: [],
      filter: ''
    };
  },
  computed: {
    behandler: function behandler() {
      var rgx = new RegExp(this.filter, 'i');
      var behandlerMap = behandlerStore.getters.map;
      var configurableBehandler = editSettingsStore.getters.terminBehandler;

      if (!this.filter) {
        return configurableBehandler;
      }

      return chain(configurableBehandler).map(function (extid) {
        return behandlerMap[extid];
      }).filter(function (b) {
        return rgx.test(b.displayText || '') || rgx.test(b.extid || '') || chain(getTerminBehandler(editSettingsStore.state.editSettings, b.extid)).map(function (tb) {
          return behandlerMap[tb.extid];
        }).compact().some(function (t) {
          return rgx.test((t === null || t === void 0 ? void 0 : t.extid) || '') || rgx.test((t === null || t === void 0 ? void 0 : t.displayText) || '');
        }).value();
      }).map(function (b) {
        return b.extid;
      }).value();
    },
    behandlerNichtZugeordnetAktiv: function behandlerNichtZugeordnetAktiv() {
      return editSettingsStore.getters.behandlerNichtZugeordnetAktiv;
    },
    behandlerNichtZugeordnetVeraltet: function behandlerNichtZugeordnetVeraltet() {
      return editSettingsStore.getters.behandlerNichtZugeordnetVeraltet;
    }
  },
  created: function created() {
    this.gridOptions = {
      rowHeight: 50,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        }
      },
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressCellFocus: true
    };
    this.$log.debug('table data', this.behandler);
  },
  methods: {
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('Cell clicked', event);
    },
    onSelectionChanged: function onSelectionChanged() {
      this.showDeleteAllSelected = !!this.gridApi.getSelectedRows().length;
    },
    deleteItems: function deleteItems() {
      var selected = this.gridApi.getSelectedRows();
      var items = selected.map(function (v) {
        return v.extid;
      });
      editSettingsStore.commit.deleteBehandlerFromZuordnung(items);
      this.showDeleteAllSelected = false;
      this.gridApi.deselectAll();
    },
    addBehandlerToZuordnung: function addBehandlerToZuordnung(data) {
      editSettingsStore.commit.addBehandlerToZuordnung(data);
    },
    getRowId: function getRowId(params) {
      return params.data;
    }
  }
});