import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.error.to-string.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.date.now.js";
import "core-js/modules/es.function.name.js";
import { defineComponent } from 'vue';
import { german } from '@rose/common-ui';
import { settingsBehandlerTeamsCustomTeamsColumnsDefs } from '@/components/columns/settings/behandlerTeamsCustomTeams';
import { settingsBehandlerTeamsAutoTeamsColumnsDefs } from '@/components/columns/settings/behandlerTeamsAutoTeams';
import { TeamTypes } from '@rose/types';
import { behandlerStore } from '@/state/behandlerStore';
import { isEmpty } from 'lodash';
import AutoCompleteBehandler from '@/components/AutoCompleteBehandler.vue';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
var teamFormInitial = {
  name: '',
  typ: '',
  farbe: '',
  mitglieder: []
};
export default defineComponent({
  components: {
    AutoCompleteBehandler: AutoCompleteBehandler
  },
  props: {
    isCustom: {
      type: Boolean,
      "default": false
    }
  },
  data: function data() {
    return {
      localeText: german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: null,
      showDeleteAllSelected: false,
      teamForm: teamFormInitial,
      teamFormValid: false,
      teamTypOptions: [{
        name: 'Team',
        value: TeamTypes.TEAM
      }, {
        name: 'Bündel',
        value: TeamTypes.BUNDLE
      }],
      noColor: true,
      menu: false
    };
  },
  computed: {
    teams: function teams() {
      return this.isCustom ? editSettingsStore.getters.customTeams : editSettingsStore.getters.autoTeams;
    },
    mitglieder: function mitglieder() {
      return behandlerStore.getters.values;
    }
  },
  watch: {
    teamForm: {
      handler: function handler(newValue, oldValue) {
        this.checkValidTeam();
      },
      deep: true
    }
  },
  beforeMount: function beforeMount() {
    this.columnDefs = this.isCustom ? settingsBehandlerTeamsCustomTeamsColumnsDefs() : settingsBehandlerTeamsAutoTeamsColumnsDefs();
    this.gridOptions = {
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        }
      },
      rowHeight: 50,
      suppressRowClickSelection: true,
      suppressCellFocus: true
    };
  },
  methods: {
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('Cell clicked', event);
    },
    deleteItems: function deleteItems() {
      var selected = this.getSelectedRows();
      var items = selected.map(function (v) {
        return v.id;
      });
      editSettingsStore.commit.deleteTeams({
        teamIds: items
      });
      this.showDeleteAllSelected = false;
      this.gridApi.deselectAll();
    },
    getSelectedRows: function getSelectedRows() {
      return this.gridApi.getSelectedRows();
    },
    onSelectionChanged: function onSelectionChanged() {
      this.showDeleteAllSelected = !!this.getSelectedRows().length;
    },
    addTeam: function addTeam(mitglieder) {
      editSettingsStore.commit.addTeam({
        team: Object.assign(Object.assign({}, this.teamForm), {
          mitglieder: mitglieder,
          size: mitglieder.length,
          id: Date.now().toString()
        })
      });
      this.teamForm.name = '';
      this.teamForm.typ = '';
      this.teamForm.farbe = '';
      this.teamForm.mitglieder = [];
      this.noColor = true;
    },
    checkValidTeam: function checkValidTeam() {
      this.teamFormValid = !isEmpty(this.teamForm.name) && !isEmpty(this.teamForm.typ);
    },
    setNewValues: function setNewValues() {
      this.noColor = false;
      this.menu = false;
    },
    resetColor: function resetColor() {
      this.menu = false;
      this.noColor = true;
      this.teamForm.farbe = '';
    }
  }
});